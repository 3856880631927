// src/pages/Login.js

import React, { useState, useEffect } from 'react';
import api, { setAuthToken } from '../services/api';
import { useNavigate } from 'react-router-dom';
import machado from "../machado.png"; // Importar a imagem do machado

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // Estado para mensagens de erro
    const navigate = useNavigate();

    // useEffect para definir o título da página
    useEffect(() => {
        document.title = 'TEMPLARIOS';
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage(''); // Resetar mensagem de erro
        try {
            const response = await api.post('/auth/login', { username, password });
            const token = response.data.token;
            localStorage.setItem('token', token);
            setAuthToken(token);
            navigate('/dashboard/ranking'); // Redirecionar para a aba Ranking
        } catch (error) {
            console.error('Erro de login:', error);
            setErrorMessage('Credenciais inválidas');
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-green-800 to-yellow-600">
            <div className="bg-gray-900 p-8 rounded-lg shadow-lg w-96">
                {/* Header com o nome da tribo e slogan */}
                <div className="text-center mb-6">
                    <h1 className="text-4xl font-bold text-yellow-300">TEMPLARIOS</h1>
                    <p className="text-lg text-gray-300 mt-2">VEM PRA CIMA GALO...</p>
                </div>

                {/* Ícone do Machado */}
                <div className="mb-6 flex justify-center">
                    <img src={machado} alt="Machado" className="w-24 h-24 object-contain" />
                </div>

                {/* Mensagem de Erro */}
                {errorMessage && (
                    <div className="mb-4 text-red-500 text-center">
                        {errorMessage}
                    </div>
                )}

                {/* Formulário de Login */}
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <input
                            type="text"
                            placeholder="Usuário"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className="block w-full p-3 border border-yellow-300 rounded-lg bg-gray-800 text-yellow-200 focus:outline-none focus:ring-2 focus:ring-yellow-500 transition duration-200"
                            required
                        />
                    </div>
                    <div className="mb-6">
                        <input
                            type="password"
                            placeholder="Senha"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="block w-full p-3 border border-yellow-300 rounded-lg bg-gray-800 text-yellow-200 focus:outline-none focus:ring-2 focus:ring-yellow-500 transition duration-200"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-yellow-500 hover:bg-yellow-600 text-gray-900 p-3 rounded-lg font-semibold transition duration-200"
                    >
                        Entrar
                    </button>
                </form>
            </div>
        </div>
    );
}

export default Login;
