import React, { useEffect, useState } from 'react';
import api from '../services/api';
import { useNavigate } from 'react-router-dom';

function AdminPanel() {
    const [usuarios, setUsuarios] = useState([]);
    const [novoUsuario, setNovoUsuario] = useState({ username: '', password: '', role: 'player' });
    const navigate = useNavigate();

    useEffect(() => {
        carregarUsuarios();
    }, []);

    const carregarUsuarios = async () => {
        try {
            const response = await api.get('/users');
            setUsuarios(response.data);
        } catch (error) {
            alert('Erro ao carregar usuários');
        }
    };

    const criarUsuario = async () => {
        try {
            await api.post('/users', novoUsuario);
            setNovoUsuario({ username: '', password: '', role: 'player' });
            carregarUsuarios();
        } catch (error) {
            alert('Erro ao criar usuário');
        }
    };

    // Implementar edição e exclusão de usuários...

    return (
        <div className="p-4">
            <h1 className="text-2xl mb-4">Painel Administrativo</h1>
            {/* Formulário para criar novo usuário */}
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Usuário"
                    value={novoUsuario.username}
                    onChange={(e) => setNovoUsuario({ ...novoUsuario, username: e.target.value })}
                    className="p-2 border rounded mr-2"
                />
                <input
                    type="password"
                    placeholder="Senha"
                    value={novoUsuario.password}
                    onChange={(e) => setNovoUsuario({ ...novoUsuario, password: e.target.value })}
                    className="p-2 border rounded mr-2"
                />
                <select
                    value={novoUsuario.role}
                    onChange={(e) => setNovoUsuario({ ...novoUsuario, role: e.target.value })}
                    className="p-2 border rounded mr-2"
                >
                    <option value="player">Jogador</option>
                    <option value="leader">Líder</option>
                    <option value="admin">Administrador</option>
                </select>
                <button onClick={criarUsuario} className="bg-green-500 text-white p-2 rounded">Criar Usuário</button>
            </div>
            {/* Lista de usuários */}
            <table className="min-w-full bg-white">
                <thead>
                    <tr>
                        <th className="py-2 px-4 border-b">ID</th>
                        <th className="py-2 px-4 border-b">Usuário</th>
                        <th className="py-2 px-4 border-b">Nível de Acesso</th>
                        <th className="py-2 px-4 border-b">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {usuarios.map((user) => (
                        <tr key={user.id}>
                            <td className="py-2 px-4 border-b">{user.id}</td>
                            <td className="py-2 px-4 border-b">{user.username}</td>
                            <td className="py-2 px-4 border-b">{user.role}</td>
                            <td className="py-2 px-4 border-b">
                                {/* Botões para editar e excluir */}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default AdminPanel;
