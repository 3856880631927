// src/components/Layout.js
import React from 'react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';

function Layout() {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/');
    };

    return (
        <div className="min-h-screen flex flex-col">
            {/* Menu Superior */}
            <header className="bg-gradient-to-r from-green-800 to-yellow-600 text-white p-4 flex justify-between items-center">
                <nav className="flex space-x-4">
                    <NavLink
                        to="/dashboard/ranking"
                        className={({ isActive }) =>
                            isActive
                                ? 'underline font-bold text-yellow-300'
                                : 'hover:underline text-yellow-200'
                        }
                    >
                        Ranking
                    </NavLink>
                    <NavLink
                        to="/dashboard/progresso"
                        className={({ isActive }) =>
                            isActive
                                ? 'underline font-bold text-yellow-300'
                                : 'hover:underline text-yellow-200'
                        }
                    >
                        Progresso
                    </NavLink>
                    <NavLink
                        to="/dashboard/tropas"
                        className={({ isActive }) =>
                            isActive
                                ? 'underline font-bold text-yellow-300'
                                : 'hover:underline text-yellow-200'
                        }
                    >
                        Tropas
                    </NavLink>
                    {/* Adicione mais abas aqui conforme necessário */}
                </nav>
                <button
                    onClick={handleLogout}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition duration-200"
                >
                    Sair
                </button>
            </header>

            {/* Conteúdo Principal */}
            <main className="flex-grow p-6 bg-gray-900">
                <Outlet />
            </main>

            {/* Rodapé */}
            <footer className="bg-gradient-to-r from-green-800 to-yellow-600 text-white p-4 text-center">
                © {new Date().getFullYear()} TEMPLARIOS BR130
            </footer>
        </div>
    );
}

export default Layout;
