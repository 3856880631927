// src/pages/Tropas.js
import React, { useEffect, useState } from 'react';
import api from '../services/api';

function Tropas() {
    const [jogadores, setJogadores] = useState([]);
    const [selectedJogador, setSelectedJogador] = useState(null);
    const [aldeias, setAldeias] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        carregarJogadores();
    }, []);

    const carregarJogadores = async () => {
        setLoading(true);
        try {
            const response = await api.get('/dados', {
                params: {
                    // Filtros se necessário
                },
            });
            const dados = response.data;

            // Processar os jogadores únicos
            const jogadoresMap = new Map();
            dados.forEach((item) => {
                if (!jogadoresMap.has(item.nome)) {
                    jogadoresMap.set(item.nome, []);
                }
                if (item.aldeia_id) {
                    jogadoresMap.get(item.nome).push({
                        id: item.aldeia_id,
                        nome: item.aldeia_nome,
                        tropas: JSON.parse(item.aldeia_tropas),
                        total_tropas: item.aldeia_total_tropas
                    });
                }
            });

            const jogadoresList = Array.from(jogadoresMap.entries()).map(([nome, aldeias]) => ({
                nome,
                aldeias
            }));

            setJogadores(jogadoresList);
        } catch (error) {
            console.error('Erro ao carregar jogadores:', error);
            alert('Erro ao carregar jogadores.');
        }
        setLoading(false);
    };

    const handleJogadorClick = (jogador) => {
        setSelectedJogador(jogador);
        setAldeias(jogador.aldeias);
    };

    return (
        <div className="p-4 bg-gray-900 min-h-full text-yellow-200">
            <h1 className="text-2xl mb-4 text-yellow-300">Tropas dos Jogadores</h1>
            {loading ? (
                <div className="flex justify-center items-center">
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-yellow-500 h-12 w-12"></div>
                </div>
            ) : (
                <div className="flex flex-col md:flex-row">
                    {/* Lista de Jogadores */}
                    <div className="w-full md:w-1/3 bg-gray-800 p-4 rounded-lg mr-4 mb-4 md:mb-0">
                        <h2 className="text-xl mb-2 text-yellow-300">Jogadores</h2>
                        <ul>
                            {jogadores.map((jogador, index) => (
                                <li
                                    key={index}
                                    onClick={() => handleJogadorClick(jogador)}
                                    className={`cursor-pointer p-2 rounded mb-2 ${
                                        selectedJogador && selectedJogador.nome === jogador.nome
                                            ? 'bg-yellow-500 text-gray-900'
                                            : 'bg-gray-700 hover:bg-gray-600'
                                    } transition duration-200`}
                                >
                                    {jogador.nome}
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* Detalhes das Aldeias */}
                    <div className="w-full md:w-2/3 bg-gray-800 p-4 rounded-lg">
                        {selectedJogador ? (
                            <div>
                                <h2 className="text-xl mb-2 text-yellow-300">Aldeias de {selectedJogador.nome}</h2>
                                {aldeias.length === 0 ? (
                                    <p className="text-gray-400">Nenhuma aldeia encontrada para este jogador.</p>
                                ) : (
                                    <table className="min-w-full bg-gray-700 text-yellow-200">
                                        <thead>
                                            <tr>
                                                <th className="py-2 px-4 border-b border-gray-600">Aldeia</th>
                                                <th className="py-2 px-4 border-b border-gray-600">Lanceiro</th>
                                                <th className="py-2 px-4 border-b border-gray-600">Espadachim</th>
                                                <th className="py-2 px-4 border-b border-gray-600">Bárbaro</th>
                                                <th className="py-2 px-4 border-b border-gray-600">Explorador</th>
                                                <th className="py-2 px-4 border-b border-gray-600">Cavalaria Leve</th>
                                                <th className="py-2 px-4 border-b border-gray-600">Cavalaria Pesada</th>
                                                <th className="py-2 px-4 border-b border-gray-600">Aríete</th>
                                                <th className="py-2 px-4 border-b border-gray-600">Catapulta</th>
                                                <th className="py-2 px-4 border-b border-gray-600">Paladino</th>
                                                <th className="py-2 px-4 border-b border-gray-600">Nobre</th>
                                                <th className="py-2 px-4 border-b border-gray-600">Milícia</th>
                                                <th className="py-2 px-4 border-b border-gray-600">Total de Tropas</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {aldeias.map((aldeia, index) => (
                                                <tr key={index} className="hover:bg-gray-600">
                                                    <td className="py-2 px-4 border-b border-gray-600">{aldeia.nome}</td>
                                                    {aldeia.tropas.map((tropa, idx) => (
                                                        <td key={idx} className="py-2 px-4 border-b border-gray-600">
                                                            {tropa}
                                                        </td>
                                                    ))}
                                                    <td className="py-2 px-4 border-b border-gray-600">{aldeia.total_tropas.toLocaleString('pt-BR')}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        ) : (
                            <p className="text-gray-400">Selecione um jogador para ver suas aldeias e tropas.</p>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Tropas;
