// services/api.js
import axios from 'axios';

const api = axios.create({
    baseURL: 'https://api-tw.y2audio.online/api',
});

export function setAuthToken(token) {
    if (token) {
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete api.defaults.headers.common['Authorization'];
    }
}

export default api;
