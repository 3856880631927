// src/pages/Progresso.js
import React, { useEffect, useState } from 'react';
import api from '../services/api';

function Progresso() {
    const [dadosAtuais, setDadosAtuais] = useState([]);
    const [dadosAnteriores, setDadosAnteriores] = useState([]);
    const [intervalo, setIntervalo] = useState(180); // Intervalo em minutos (default: 3 horas)
    const [progresso, setProgresso] = useState([]);
    const [loading, setLoading] = useState(false);

    // Definir os intervalos em minutos (3h, 6h, ..., 24h, 2 dias, 3 dias, 1 semana)
    const intervalosDisponiveis = [
        { label: 'Últimas 3 Horas', value: 180 },
        { label: 'Últimas 6 Horas', value: 360 },
        { label: 'Últimas 9 Horas', value: 540 },
        { label: 'Últimas 12 Horas', value: 720 },
        { label: 'Últimas 15 Horas', value: 900 },
        { label: 'Últimas 18 Horas', value: 1080 },
        { label: 'Últimas 21 Horas', value: 1260 },
        { label: 'Últimas 24 Horas', value: 1440 },
        { label: 'Últimos 2 Dias', value: 2880 }, // 2 dias
        { label: 'Últimos 3 Dias', value: 4320 }, // 3 dias
        { label: 'Última Semana', value: 10080 },  // 7 dias
    ];

    useEffect(() => {
        carregarDadosAtuais();
        carregarDadosAnteriores();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intervalo]); // Executar quando o intervalo muda

    useEffect(() => {
        calcularProgresso();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dadosAtuais, dadosAnteriores]);

    const carregarDadosAtuais = async () => {
        setLoading(true);
        try {
            const response = await api.get('/dados', {
                params: {
                    // Buscar todos os dados para filtrar no frontend
                },
            });
            const dadosRecebidos = response.data;

            // Filtrar apenas os dados mais recentes de cada jogador
            const dadosMaisRecentes = dadosRecebidos.reduce((acc, curr) => {
                const jogadorExistente = acc.find(j => j.nome === curr.nome);
                if (!jogadorExistente || new Date(curr.timestamp) > new Date(jogadorExistente.timestamp)) {
                    acc = acc.filter(j => j.nome !== curr.nome);
                    acc.push(curr);
                }
                return acc;
            }, []);

            setDadosAtuais(dadosMaisRecentes);
        } catch (error) {
            console.error('Erro ao carregar dados atuais:', error);
            alert('Erro ao carregar dados atuais');
        }
        setLoading(false);
    };

    const carregarDadosAnteriores = async () => {
        setLoading(true);
        try {
            const response = await api.get('/dados', {
                params: {
                    // Buscar todos os dados para filtrar no frontend
                },
            });
            const dadosRecebidos = response.data;

            // Calcular o timestamp limite
            const agora = new Date();
            const limite = new Date(agora.getTime() - intervalo * 60000); // intervalo em minutos

            // Filtrar os dados anteriores dentro do intervalo
            const dadosFiltrados = dadosRecebidos.filter(d => new Date(d.timestamp) <= limite);

            // Obter os dados mais recentes dentro do intervalo para cada jogador
            const dadosMaisRecentes = dadosFiltrados.reduce((acc, curr) => {
                const jogadorExistente = acc.find(j => j.nome === curr.nome);
                if (!jogadorExistente || new Date(curr.timestamp) > new Date(jogadorExistente.timestamp)) {
                    acc = acc.filter(j => j.nome !== curr.nome);
                    acc.push(curr);
                }
                return acc;
            }, []);

            setDadosAnteriores(dadosMaisRecentes);
        } catch (error) {
            console.error('Erro ao carregar dados anteriores:', error);
            alert('Erro ao carregar dados anteriores');
        }
        setLoading(false);
    };

    const calcularProgresso = () => {
        const progressoCalculado = dadosAtuais.map(atual => {
            const anterior = dadosAnteriores.find(a => a.nome === atual.nome);
            if (anterior) {
                const mudanças = {};

                // Listar as propriedades que queremos acompanhar
                const propriedades = [
                    'lanceiro',
                    'espadachim',
                    'barbaro',
                    'explorador',
                    'cavalaria_leve',
                    'cavalaria_pesada',
                    'ariete',
                    'catapulta',
                    'paladino',
                    'nobre',
                    'militia',
                    'total_tropas',
                    'ODA',
                    'ODD',
                    'ODS',
                    'Farm',
                    'Coleta',
                    'ODA_Total',
                    'ODD_Total',
                    'ODS_Total',
                ];

                propriedades.forEach(prop => {
                    const aumento = (atual[prop] || 0) - (anterior[prop] || 0);
                    if (aumento !== 0) {
                        mudanças[prop] = aumento;
                    }
                });

                // Verificar se houve alguma mudança
                const nenhumaMudanca = Object.keys(mudanças).length === 0;

                return {
                    nome: atual.nome,
                    mudanças,
                    nenhumaMudanca, // Nova propriedade
                };
            } else {
                // Se não há dados anteriores, considere como novo
                return {
                    nome: atual.nome,
                    mudanças: {
                        total_tropas: atual.total_tropas || 0,
                        // Adicione outras propriedades se necessário
                    },
                    nenhumaMudanca: false, // Considera que evoluiu se é novo
                };
            }
        });

        setProgresso(progressoCalculado);
    };

    const handleIntervaloChange = (value) => {
        setIntervalo(value);
    };

    return (
        <div className="p-4 bg-gray-900 min-h-full text-yellow-200">
            <h1 className="text-2xl mb-4 text-yellow-300">Progresso dos Jogadores</h1>
            {/* Configuração do Intervalo de Tempo */}
            <div className="mb-4 flex flex-wrap items-center space-x-2">
                <span className="font-semibold text-yellow-300">Intervalo de Tempo:</span>
                {intervalosDisponiveis.map((item) => (
                    <button
                        key={item.value}
                        onClick={() => handleIntervaloChange(item.value)}
                        className={`px-4 py-2 rounded mb-2 ${
                            intervalo === item.value
                                ? 'bg-yellow-500 text-gray-900'
                                : 'bg-gray-700 text-yellow-200 hover:bg-gray-600'
                        } transition duration-200`}
                    >
                        {item.label}
                    </button>
                ))}
            </div>
            {/* Botão de Atualizar */}
            <div className="mb-4">
                <button
                    onClick={() => {
                        carregarDadosAtuais();
                        carregarDadosAnteriores();
                    }}
                    className="bg-yellow-500 hover:bg-yellow-600 text-gray-900 font-bold py-2 px-4 rounded transition duration-200"
                >
                    Atualizar
                </button>
            </div>
            {/* Indicador de Carregamento */}
            {loading ? (
                <div className="flex justify-center items-center">
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-yellow-500 h-12 w-12"></div>
                </div>
            ) : (
                /* Lista de Progresso */
                <div>
                    {progresso.length === 0 ? (
                        <p className="text-gray-400">Nenhum progresso registrado nos últimos {formatIntervalo(intervalo)}.</p>
                    ) : (
                        <table className="min-w-full bg-gray-800 text-yellow-200">
                            <thead>
                                <tr>
                                    <th className="py-2 px-4 border-b border-gray-700">Jogador</th>
                                    <th className="py-2 px-4 border-b border-gray-700">Mudanças</th>
                                </tr>
                            </thead>
                            <tbody>
                                {progresso.map((item, index) => {
                                    const temMudancas = Object.keys(item.mudanças).length > 0;
                                    return (
                                        <tr
                                            key={index}
                                            className={`hover:bg-gray-700 transition duration-200 ${
                                                !temMudancas
                                                    ? 'bg-yellow-500 animate-pulse'
                                                    : ''
                                            }`}
                                        >
                                            <td className="py-2 px-4 border-b border-gray-700">{item.nome}</td>
                                            <td className="py-2 px-4 border-b border-gray-700">
                                                {Object.entries(item.mudanças).map(([key, value], idx) => (
                                                    <div key={idx} className="flex items-center">
                                                        <strong className="mr-2">{formatLabel(key)}:</strong>
                                                        <span
                                                            className={`${
                                                                value > 0
                                                                    ? 'text-green-400'
                                                                    : value < 0
                                                                    ? 'text-red-400'
                                                                    : 'text-yellow-400'
                                                            }`}
                                                        >
                                                            {value > 0 ? `+${value}` : value}
                                                        </span>
                                                    </div>
                                                ))}
                                                {/* Se não houver mudanças, exibir um aviso */}
                                                {!temMudancas && (
                                                    <div className="mt-2 text-yellow-300">
                                                        Nenhuma mudança registrada.
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    )}
                </div>
            )}
        </div>
    )}

    // Função para formatar os labels das propriedades
    function formatLabel(label) {
        switch (label) {
            case 'lanceiro':
                return 'Lanceiro';
            case 'espadachim':
                return 'Espadachim';
            case 'barbaro':
                return 'Bárbaro';
            case 'explorador':
                return 'Explorador';
            case 'cavalaria_leve':
                return 'Cavalaria Leve';
            case 'cavalaria_pesada':
                return 'Cavalaria Pesada';
            case 'ariete':
                return 'Aríete';
            case 'catapulta':
                return 'Catapulta';
            case 'paladino':
                return 'Paladino';
            case 'nobre':
                return 'Nobre';
            case 'militia':
                return 'Milícia';
            case 'total_tropas':
                return 'Total de Tropas';
            case 'ODA':
                return 'ODA';
            case 'ODD':
                return 'ODD';
            case 'ODS':
                return 'ODS';
            case 'Farm':
                return 'Farm';
            case 'Coleta':
                return 'Coleta';
            case 'ODA_Total':
                return 'ODA Total';
            case 'ODD_Total':
                return 'ODD Total';
            case 'ODS_Total':
                return 'ODS Total';
            default:
                return label;
        }
    }

    // Função para formatar o intervalo para exibição
    function formatIntervalo(intervalo) {
        switch (intervalo) {
            case 180:
                return '3 Horas';
            case 360:
                return '6 Horas';
            case 540:
                return '9 Horas';
            case 720:
                return '12 Horas';
            case 900:
                return '15 Horas';
            case 1080:
                return '18 Horas';
            case 1260:
                return '21 Horas';
            case 1440:
                return '24 Horas';
            case 2880:
                return '2 Dias';
            case 4320:
                return '3 Dias';
            case 10080:
                return '1 Semana';
            default:
                return `${intervalo} minutos`;
        }
    }

    export default Progresso;
