// src/components/SortableTable.js
import React from 'react';

function SortableTable({ data, columns, sortConfig, onSort }) {
    const sortedData = React.useMemo(() => {
        let sortableData = [...data];
        if (sortConfig !== null) {
            sortableData.sort((a, b) => {
                const aValue = a[sortConfig.key] !== undefined ? a[sortConfig.key] : '';
                const bValue = b[sortConfig.key] !== undefined ? b[sortConfig.key] : '';

                if (typeof aValue === 'number' && typeof bValue === 'number') {
                    return sortConfig.direction === 'ascending' ? aValue - bValue : bValue - aValue;
                }

                // Converter para string para evitar erros de comparação
                const aStr = aValue.toString();
                const bStr = bValue.toString();

                if (aStr < bStr) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aStr > bStr) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableData;
    }, [data, sortConfig]);

    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }
        onSort({ key, direction });
    };

    return (
        <div className="overflow-x-auto">
            <table className="min-w-full bg-gray-800 text-yellow-200">
                <thead>
                    <tr>
                        {columns.map((column) => (
                            <th
                                key={column.key}
                                className={`py-2 px-4 border-b border-yellow-500 cursor-pointer ${
                                    sortConfig.key === column.key ? 'bg-yellow-500 text-gray-900' : 'hover:bg-yellow-600'
                                } transition duration-200`}
                                onClick={() => handleSort(column.key)}
                                aria-sort={
                                    sortConfig.key === column.key
                                        ? sortConfig.direction === 'ascending'
                                            ? 'ascending'
                                            : 'descending'
                                        : 'none'
                                }
                            >
                                {column.label}{' '}
                                {getClassNamesFor(column.key) === 'ascending' ? '▲' : getClassNamesFor(column.key) === 'descending' ? '▼' : ''}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {sortedData.map((item, index) => (
                        <tr
                            key={index}
                            className={`hover:bg-gray-700 ${
                                item.naoEvoluiu ? 'bg-red-700' : ''
                            } transition duration-200`}
                        >
                            {columns.map((column) => (
                                <td key={column.key} className="py-2 px-4 border-b border-yellow-500">
                                    {item[column.key] !== undefined ? item[column.key] : '-'}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default SortableTable;
