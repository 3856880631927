// src/pages/Ranking.js
import React, { useEffect, useState } from 'react';
import api from '../services/api';
import SortableTable from '../components/SortableTable';

function Ranking() {
    const [dados, setDados] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: 'total_tropas', direction: 'descending' });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        carregarDados();
    }, []);

    const carregarDados = async () => {
        setLoading(true);
        try {
            const response = await api.get('/dados', {
                params: {
                    // Não adicionar filtros de data
                },
            });
            const dadosRecebidos = response.data;

            // Filtrar apenas os dados mais recentes de cada jogador
            const dadosMaisRecentes = dadosRecebidos.reduce((acc, curr) => {
                const jogadorExistente = acc.find(j => j.nome === curr.nome);
                if (!jogadorExistente || new Date(curr.timestamp) > new Date(jogadorExistente.timestamp)) {
                    acc = acc.filter(j => j.nome !== curr.nome);
                    acc.push(curr);
                }
                return acc;
            }, []);

            setDados(dadosMaisRecentes);
        } catch (error) {
            console.error('Erro ao carregar dados:', error);
            alert('Erro ao carregar dados');
        }
        setLoading(false);
    };

    const columns = [
        { key: 'nome', label: 'Jogador' },
        { key: 'lanceiro', label: 'Lanceiro' },
        { key: 'espadachim', label: 'Espadachim' },
        { key: 'barbaro', label: 'Bárbaro' },
        { key: 'explorador', label: 'Explorador' },
        { key: 'cavalaria_leve', label: 'Cavalaria Leve' },
        { key: 'cavalaria_pesada', label: 'Cavalaria Pesada' },
        { key: 'ariete', label: 'Aríete' },
        { key: 'catapulta', label: 'Catapulta' },
        { key: 'paladino', label: 'Paladino' },
        { key: 'nobre', label: 'Nobre' },
        { key: 'militia', label: 'Milícia' },
        { key: 'total_tropas', label: 'Total de Tropas' },
        { key: 'ODA', label: 'ODA' },
        { key: 'ODD', label: 'ODD' },
        { key: 'ODS', label: 'ODS' },
        { key: 'Farm', label: 'Farm' },
        { key: 'Coleta', label: 'Coleta' },
        { key: 'ODA_Total', label: 'ODA Total' },
        { key: 'ODD_Total', label: 'ODD Total' },
        { key: 'ODS_Total', label: 'ODS Total' },
    ];

    return (
        <div className="p-4 bg-gray-900 min-h-full">
            <h1 className="text-2xl mb-4 text-yellow-300">Ranking de Tropas e Estatísticas</h1>
            {/* Tabela de Ranking */}
            {loading ? (
                <div className="flex justify-center items-center">
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-yellow-500 h-12 w-12"></div>
                </div>
            ) : (
                <SortableTable
                    data={dados}
                    columns={columns}
                    sortConfig={sortConfig}
                    onSort={setSortConfig}
                />
            )}
        </div>
    );
}

export default Ranking;
